
import { defineComponent } from '@vue/runtime-core';
import { ref } from '@vue/reactivity';
import { getStatusPage } from '@/api/external.api';

interface StatusPageInfo {
  description: string;
  indicator: string;
  link: string;
}
export default defineComponent({
  name: 'SystemStatusBadge',
  setup() {
    const loading = ref(false);
    const statusPageInfo = ref({} as StatusPageInfo);

    const fetchStatusPage = async () => {
      loading.value = true;
      try {
        const response = await getStatusPage();
        statusPageInfo.value = {
          indicator: response.data.status.indicator,
          description: response.data.status.description,
          link: response.data.page.url,
        };
      } catch (e) {
        console.log('Error loading system status info');
        console.log(e);
      }
      loading.value = false;
    };

    fetchStatusPage();

    return {
      loading,
      statusPageInfo,
    };
  },
});

import axios from 'axios';

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

const getStatusPage = () =>
  httpClient.get(`https://status.gordiansoftware.com/api/v2/status.json`);

const getStatusPageSummary = () =>
  httpClient.get(`https://status.gordiansoftware.com/api/v2/summary.json`);

export { getStatusPage, getStatusPageSummary };

import moment from 'moment';

export function removeUnderscores(string: string): string {
  return string.replace(/_/g, ' ');
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function interpretOptionalString(string: string | undefined): string {
  return string == null ? 'N/A' : string;
}

export function titlize(string = ''): string {
  if (string == '') return '';
  const words = removeUnderscores(string).split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(' ');
}

export function formatDate(dateString: string) {
  if (dateString) {
    return moment(String(dateString)).format('MMM D YYYY hh:mm A Z');
  }
  return dateString;
}

export function formatCurrency(
  amount: number,
  decimalPlaces: number,
  currency: string
) {
  const divisor = Math.pow(10, decimalPlaces);
  const adjustedAmount = amount / divisor;

  const options = {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return adjustedAmount.toLocaleString(undefined, options);
}

export const parseRole = (value: string) => {
  const newValue = value
    .split('_')
    .map(([firstLetter, ...rest]) => firstLetter.toUpperCase() + rest.join(''))
    .join(' ');
  return newValue;
};

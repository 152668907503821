
import { computed, ref } from '@vue/reactivity';
import { ExternalLinkIcon } from '@heroicons/vue/solid';
import { PencilIcon, CheckIcon, XIcon } from '@heroicons/vue/outline';
import store from '@/store';
import { getStatusPageSummary } from '@/api/external.api';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import { removeUnderscores, capitalizeFirstLetter } from '@/components/utils';
import { Integration } from '@/models';
import { updateIntegration } from '@/api/integrations.api';

export default {
  name: 'dashboard',
  components: {
    SystemStatusBadge,
    ExternalLinkIcon,
    PencilIcon,
    CheckIcon,
    XIcon,
  },
  setup() {
    const sharedState = store.state;

    const executed = ref(false);
    const statusPageInfo = ref({} as any);
    const currentEditingDisplayNameIntegrationId = ref('');
    const newDisplayName = ref('');

    const fetchStatusPage = async () => {
      executed.value = false;
      try {
        const response = await getStatusPageSummary();
        statusPageInfo.value = response.data;
      } catch (e) {
        console.log('Error loading system status info');

        console.log(e);
      }
      executed.value = true;
    };

    const mainComponents = computed(() => {
      const mainComponents: any[] = [];
      if (statusPageInfo.value) {
        statusPageInfo.value.components.forEach((component: any) => {
          if (component.components && component.components.length) {
            const subComponents: any[] = [];
            for (let i = 0; i < component.components.length; i++) {
              const subComponentId = component.components[i];
              const subComponent = statusPageInfo.value.components.filter(
                (c: any) => c.id == subComponentId
              )[0];
              subComponents.push(subComponent);
            }
            component.components = subComponents;
            mainComponents.push(component);
          }
        });
      }
      return mainComponents;
    });

    const enableEditing = (integration: Integration | null = null) => {
      if (integration) {
        currentEditingDisplayNameIntegrationId.value = integration.id;
        newDisplayName.value = integration.displayName;
        return;
      }
      currentEditingDisplayNameIntegrationId.value = '';
      newDisplayName.value = '';
    };

    const updateDisplayName = async () => {
      try {
        // TODO: Dialog with a loader indicator or change icons for the loading indicator.
        const updatedIntegration = await updateIntegration(
          currentEditingDisplayNameIntegrationId.value,
          {
            displayName: newDisplayName.value,
          }
        );
        const integrations = [...sharedState.integrations] as Integration[];
        const indexToReplace = integrations.findIndex(
          (integration: Integration) => integration.id == updatedIntegration.id
        );
        integrations.splice(indexToReplace, 1, updatedIntegration);
        store.setIntegrations(integrations);
      } catch (error) {
        // TODO: Dialog to show something went wrong
      } finally {
        currentEditingDisplayNameIntegrationId.value = '';
        newDisplayName.value = '';
      }
    };

    fetchStatusPage();

    return {
      executed,
      statusPageInfo,
      sharedState,
      mainComponents,
      removeUnderscores,
      capitalizeFirstLetter,
      currentEditingDisplayNameIntegrationId,
      newDisplayName,
      enableEditing,
      updateDisplayName,
    };
  },
};
